.flexEnd .dx-collection > .dx-item {
  align-items: flex-end !important;
}

.formButton {
  justify-content: end !important;
}

.dx-pivotgrid-vertical-headers tr:nth-child(even),
.dx-pivotgrid-area-data tr:nth-child(even) {
  background-color: #f5f5f5;
}
